import React, { Fragment, useState, useEffect, useRef, useCallback, useContext } from 'react'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import Loader from '../layouts/components/Loader'
import { AuthContext } from '../context/auth-context'
import { GuideContext } from '../context/guide-context'
import AudienceCounter from '../components/dashboard/AudienceCounter'
import AudienceChart from '../components/dashboard/AudienceChart'
import AudiencePieChart from '../components/dashboard/AudiencePieChart'
import PlatformAudienceChart from '../components/dashboard/PlatformAudienceChart'
import LiveCampaignPerformanceChart from '../components/dashboard/LiveCampaignPerformanceChart'
import ConversionRateChart from '../components/dashboard/ConversionRateChart'
import AddToCartWidget from '../components/dashboard/AddToCartWidget'
import PurchaseWidget from '../components/dashboard/PurchaseWidget'
import RoasWidget from '../components/dashboard/RoasWidget'
import CtrWidget from '../components/dashboard/CtrWidget'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.css'
import _ from 'lodash'

const GET_USER = gql`
    query Users($where: UserWhere) {
        users(where: $where) {
            id
            paymentMethodId
            address
            phone
        }
    }
`

const GET_PLATFORMS = gql`
    query Platforms($options: PlatformOptions, $where: PlatformWhere) {
        platforms(options: $options, where: $where) {
            id
            name
        }
    }`

const GET_CAMPAIGN = gql`
    query Campaigns {
        campaigns {
            audience
        }
    }`

const GET_INSIGHTS = gql`
    query Publishers($publishersWhere: PublisherWhere, $adCampaignsWhere: AdCampaignWhere, $options: AdCampaignOptions) {
        publishers(where: $publishersWhere) {
            name
            adCampaigns(where: $adCampaignsWhere, options: $options) {
                conversion
                costPerConversion
                impressions
                uniqueCtr
                spend
                addToCart
                addToCartValue
                purchase
                purchaseValue
                createdAt
                landingPagesConnection {
                    edges {
                        sales
                        conversionRate
                        node {
                            id
                        }
                    }
                }
            }
        }
    }`

const GET_CUSTOM_INSIGHTS = gql`
    query PublisherCustomInsights($from: DateTime!, $to: DateTime!) {
        publisherCustomInsights(from: $from, to: $to) {
            id
            name
            addToCart
            addToCartValue
            conversion
            costPerConversion
            uniqueCtr
            purchase
            purchaseValue
            spend
        }
    }`

const Dashboard = () => {
    const { user } = useContext(AuthContext)
    const { guide } = useContext(GuideContext)
    const navigate = useNavigate()
    const fp = useRef(null)
    const [totalAudience, setTotalAudience] = useState({})
    const [audience, setAudience] = useState([])
    const [data, setData] = useState({
        spend: 0,
        landingPagesConnection: {
            edges: []
        }
    })
    const [period, setPeriod] = useState(null)

    const userQuery = useQuery(GET_USER, {
        variables: { where: { id: user.id } },
        onCompleted: (data) => {
            // If this is a fresh user, redirect to profile.
            if (data?.users[0] && guide === 'on') {
                if (!data.users[0].paymentMethodId || !data.users[0].address || !data.users[0].phone) {
                    navigate('/profile')
                }
            }
        }
    })

    const [getCustomInsights, customInsights] = useLazyQuery(GET_CUSTOM_INSIGHTS, {
        fetchPolicy: 'network-only'
    })
    const campaign = useQuery(GET_CAMPAIGN)

    const insights = useQuery(GET_INSIGHTS, {
        variables: {
            adCampaignsWhere: {},
            options: { sort: [{ createdAt: 'ASC' }]}
        }
    })

    const platforms = useQuery(GET_PLATFORMS, {
        variables: { options: { sort: [{ order: 'ASC' }] }, where: { status: 'ACTIVE' } }
    })

    const combineData = useCallback((data) => {
        const combinedData = {}

        // Filter out Ad Campaigns without CTR data.
        const ctrData = data.filter((item) => item.uniqueCtr)

        // Calculate CTR as a weighted average based on impressions.
        combinedData.uniqueCtr = (ctrData.reduce((sum, item) => sum + item.uniqueCtr * item.impressions, 0)) / (ctrData.reduce((sum, item) => sum + item.impressions, 0))

        combinedData.createdAt = data.reduce((acc, obj) => acc < new Date(obj.createdAt) ? acc : new Date(obj.createdAt), new Date(data[0].createdAt))
        combinedData.addToCart = data.reduce((acc, obj) => acc + obj.addToCart, 0)
        combinedData.addToCartValue = data.reduce((acc, obj) => acc + obj.addToCartValue, 0)
        combinedData.conversion = data.reduce((acc, obj) => acc + obj.conversion, 0)
        combinedData.purchase = data.reduce((acc, obj) => acc + obj.purchase, 0)
        combinedData.purchaseValue = data.reduce((acc, obj) => acc + obj.purchaseValue, 0)
        combinedData.spend = data.reduce((acc, obj) => acc + obj.spend, 0)

        combinedData.costPerConversion = combinedData.conversion > 0 ? combinedData.spend / combinedData.conversion : 0

        setData(combinedData)
    }, [])

    const defaultPeriod = useCallback((startDate) => {
        const start = startDate ? new Date(startDate) : new Date()
        const end = new Date()
        return start.toLocaleDateString("en-GB", {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }) + ' - ' + end.toLocaleDateString("en-GB", {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        })
    }, [])

    const handleDateChange = async (selectedDates) => {
        // Check if both start and end dates are selected
        if (selectedDates.length === 2) {
            setPeriod(selectedDates[0].toLocaleDateString("en-GB", {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            }) + ' - ' + selectedDates[1].toLocaleDateString("en-GB", {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            }))

            await getCustomInsights({
                variables: {
                    from: selectedDates[0].toISOString().split('T')[0],
                    to: selectedDates[1].toISOString().split('T')[0]
                },
                onCompleted: (response) => {
                    const data = response.publisherCustomInsights.find((publisher) => publisher.name === 'Meta')
                    setData((prevData) => ({
                        ...prevData,
                        ...data
                    }))
                }
            })

        }
    }

    const openFlatpickr = () => {
        if (fp.current?.flatpickr) {
            setTimeout(() => fp.current.flatpickr.open(), 100)
        }
    }

    useEffect(() => {
        if (insights.data?.publishers?.length > 0) {
            const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').adCampaigns
            let startDate = null
            if (data.length > 0) {
                if (data.length > 1) {
                    combineData(data)
                } else {
                    setData(data[0])
                }
                startDate = data[0].createdAt
            }
            setPeriod(defaultPeriod(startDate))
        }
    }, [insights.data, combineData, setPeriod, defaultPeriod])

    useEffect(() => {
        if (!campaign.loading && campaign?.data?.campaigns && !platforms.loading && platforms?.data?.platforms) {
            let data

            if (campaign.data.campaigns.length > 0 && campaign.audience !== null) {
                data = campaign.data.campaigns.reduce((acc, campaign) => {
                    if (campaign.audience !== null) {
                        const campaignData = JSON.parse(campaign.audience)
                        Object.keys(campaignData).forEach(platformName => {
                            if (!acc[platformName]) {
                                acc[platformName] = {
                                    audience: 0,
                                    audienceThisWeek: Array(7).fill(0),
                                    audienceThisMonth: Array(30).fill(0),
                                    audienceThisYear: Array(12).fill(0)
                                }
                            }
                            acc[platformName].audience += campaignData[platformName].audience
                            acc[platformName].audienceThisWeek = acc[platformName].audienceThisWeek.map((val, i) => val + campaignData[platformName].audienceThisWeek[i])
                            acc[platformName].audienceThisMonth = acc[platformName].audienceThisMonth.map((val, i) => val + campaignData[platformName].audienceThisMonth[i])
                            acc[platformName].audienceThisYear = acc[platformName].audienceThisYear.map((val, i) => val + campaignData[platformName].audienceThisYear[i])
                        })
                    }
                    return acc
                }, {})
                if (Object.keys(data).length === 0) {
                    data = Object.values(platforms.data.platforms).reduce((acc, platform) => {
                        acc[platform.name] = {
                            audience: 0,
                            audienceThisWeek: Array(7).fill(0),
                            audienceThisMonth: Array(30).fill(0),
                            audienceThisYear: Array(12).fill(0)
                        }
                        return acc
                    }, {})
                }
            } else {
                data = Object.values(platforms.data.platforms).reduce((acc, platform) => {
                    acc[platform.name] = {
                        audience: 0,
                        audienceThisWeek: Array(7).fill(0),
                        audienceThisMonth: Array(30).fill(0),
                        audienceThisYear: Array(12).fill(0)
                    }
                    return acc
                }, {})
            }

            setAudience(data)

            const year = []
            const month = []
            const week = []

            Object.values(data).forEach(({ audienceThisYear }) => {
                audienceThisYear.forEach((value, index) => {
                    if (year[index] === undefined) {
                        year[index] = value
                    } else {
                        year[index] += value
                    }
                })
            })

            Object.values(data).forEach(({ audienceThisMonth }) => {
                audienceThisMonth.forEach((value, index) => {
                    if (month[index] === undefined) {
                        month[index] = value
                    } else {
                        month[index] += value
                    }
                })
            })

            Object.values(data).forEach(({ audienceThisWeek }) => {
                audienceThisWeek.forEach((value, index) => {
                    if (week[index] === undefined) {
                        week[index] = value
                    } else {
                        week[index] += value
                    }
                })
            })

            setTotalAudience({
                audienceThisWeek: week,
                audienceThisMonth: month,
                audienceThisYear: year
            })
        }
    }, [campaign, platforms])

    return (
        <Fragment>
            { platforms.loading || campaign.loading || audience.length === 0 ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="d-flex flex-wrap justify-content-between mb-4">
                        <div className="mb-0"></div>
                        <div className="d-flex flex-row">
                            <button type="button" className="btn btn-soft-light" onClick={openFlatpickr}>
                                <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-24" height="24" style={{marginRight: '10px'}}>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 16.8701V9.25708H21V16.9311C21 20.0701 19.0241 22.0001 15.8628 22.0001H8.12733C4.99561 22.0001 3 20.0301 3 16.8701ZM7.95938 14.4101C7.50494 14.4311 7.12953 14.0701 7.10977 13.6111C7.10977 13.1511 7.46542 12.7711 7.91987 12.7501C8.36443 12.7501 8.72997 13.1011 8.73985 13.5501C8.7596 14.0111 8.40395 14.3911 7.95938 14.4101ZM12.0198 14.4101C11.5653 14.4311 11.1899 14.0701 11.1701 13.6111C11.1701 13.1511 11.5258 12.7711 11.9802 12.7501C12.4248 12.7501 12.7903 13.1011 12.8002 13.5501C12.82 14.0111 12.4643 14.3911 12.0198 14.4101ZM16.0505 18.0901C15.596 18.0801 15.2305 17.7001 15.2305 17.2401C15.2206 16.7801 15.5862 16.4011 16.0406 16.3911H16.0505C16.5148 16.3911 16.8902 16.7711 16.8902 17.2401C16.8902 17.7101 16.5148 18.0901 16.0505 18.0901ZM11.1701 17.2401C11.1899 17.7001 11.5653 18.0611 12.0198 18.0401C12.4643 18.0211 12.82 17.6411 12.8002 17.1811C12.7903 16.7311 12.4248 16.3801 11.9802 16.3801C11.5258 16.4011 11.1701 16.7801 11.1701 17.2401ZM7.09989 17.2401C7.11965 17.7001 7.49506 18.0611 7.94951 18.0401C8.39407 18.0211 8.74973 17.6411 8.72997 17.1811C8.72009 16.7311 8.35456 16.3801 7.90999 16.3801C7.45554 16.4011 7.09989 16.7801 7.09989 17.2401ZM15.2404 13.6011C15.2404 13.1411 15.596 12.7711 16.0505 12.7611C16.4951 12.7611 16.8507 13.1201 16.8705 13.5611C16.8804 14.0211 16.5247 14.4011 16.0801 14.4101C15.6257 14.4201 15.2503 14.0701 15.2404 13.6111V13.6011Z" fill="currentColor"></path>
                                    <path opacity="0.4" d="M3.00293 9.25699C3.01577 8.66999 3.06517 7.50499 3.15803 7.12999C3.63224 5.02099 5.24256 3.68099 7.54442 3.48999H16.4555C18.7376 3.69099 20.3677 5.03999 20.8419 7.12999C20.9338 7.49499 20.9832 8.66899 20.996 9.25699H3.00293Z" fill="currentColor"></path>
                                    <path d="M8.30465 6.59C8.73934 6.59 9.06535 6.261 9.06535 5.82V2.771C9.06535 2.33 8.73934 2 8.30465 2C7.86996 2 7.54395 2.33 7.54395 2.771V5.82C7.54395 6.261 7.86996 6.59 8.30465 6.59Z" fill="currentColor"></path>
                                    <path d="M15.6953 6.59C16.1201 6.59 16.456 6.261 16.456 5.82V2.771C16.456 2.33 16.1201 2 15.6953 2C15.2606 2 14.9346 2.33 14.9346 2.771V5.82C14.9346 6.261 15.2606 6.59 15.6953 6.59Z" fill="currentColor"></path>
                                </svg>
                                {period}
                            </button>
                            <Flatpickr
                                ref={fp}
                                className="hidden-input"
                                onChange={handleDateChange}
                                options={{mode: 'range', showMonths: 2, maxDate: 'today'}}
                            />
                        </div>
                    </div>
                    <Row>
                        <AudienceCounter
                            platforms={platforms.data.platforms}
                            data={audience}
                        />
                        <Col lg="4">
                            <RoasWidget data={data} loading={insights.loading || customInsights.loading}/>
                            <CtrWidget data={data} loading={insights.loading || customInsights.loading}/>
                        </Col>
                        <ConversionRateChart name={'Cost and Efficiency'} data={data} loading={insights.loading || customInsights.loading}/>
                        <Col lg="4">
                            <AddToCartWidget data={data} loading={insights.loading || customInsights.loading}/>
                            <PurchaseWidget data={data} loading={insights.loading || customInsights.loading}/>
                        </Col>
                        {!_.isEmpty(totalAudience) && (
                            <AudienceChart name={'Audience Built across All Platforms'} data={totalAudience}/>
                        )}
                        <AudiencePieChart
                            name={'Audience Structure'}
                            data={platforms.data.platforms}
                            audience={audience}
                        />
                        <PlatformAudienceChart
                            name={'Audience Built per Platform'}
                            data={platforms.data.platforms}
                            audience={audience}
                        />
                        <LiveCampaignPerformanceChart name={'Campaign Performance'}/>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Dashboard