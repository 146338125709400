import React, { memo, Fragment, useState, useContext, useEffect, useRef } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import { AuthContext } from '../../context/auth-context'
import { GuideContext } from '../../context/guide-context'
import { gql, useQuery } from '@apollo/client'
import { USER_FIELDS } from '../../graphql/user-fragments'
import { Row, Col, Form } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js'
import { stripe, appearance } from '../../utils/stripe'
import PaymentDetails from '../../components/users/PaymentDetails'
import UserProfile from '../../components/users/UserProfile'
import Loader from '../../layouts/components/Loader'
import { showNotification } from '../../utils/notifications'
import { ToastContainer } from 'react-toastify'
import Card from '../../components/common/Card'
import { useOnboardingTour } from '../../utils/onboarding-tour-hook'

const GET_USER = gql`
    ${USER_FIELDS}
    query Users($where: UserWhere) {
        users(where: $where) {
            campaignsAggregate {
                count
            }
            ...UserFields
        }
    }
`

const AdminProfile = memo(() => {
    const { user } = useContext(AuthContext)
    const { guide } = useContext(GuideContext)
    const { focus, handleFocusTour } = useOnboardingTour()
    const [guidance, setGuidance] = useState(false)
    const [paymentGuidance, setPaymentGuidance] = useState(false)
    const [userData, setUserData] = useState([])
    const [notes, setNotes] = useState('')
    const { data, loading } = useQuery(GET_USER, {
        variables: { where: { id: user.id } },
    })

    const guidedRef = useRef(false)
    const guidedPaymentRef = useRef(false)
    const stripeCardFormRef = useRef(null)

    const [run, setRun] = useState(false)
    const [steps] = useState([
        {
            target: '.step-1',
            content: 'Enter your phone number',
        },
        {
            target: '.step-1-1',
            content: 'Enter your business name',
        },
        {
            target: '.step-2',
            content: 'Start typing your business address and then select it from the list',
        },
        {
            target: '.step-3',
            content: 'Click this button to save your details',
        }
    ])

    const handleFocusCardNumberFromParent = () => {
        if (stripeCardFormRef.current) {
            stripeCardFormRef.current.focusCardNumber()
        }
    }

    const handleTourCallback = (data) => {
        const { status } = data
        handleFocusTour(data, steps)
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRun(false)
        }
    }

    const updateUserData = (userData) => {
        setUserData(userData)
    }

    const onChange = (event) => {
        setNotes(event.target.value)
    }

    const userDetailsCompleted = () => {
        setPaymentGuidance(true)
        setGuidance(false)
    }

    // If this is a fresh user, show the tour.
    useEffect(() => {
        if (data?.users[0] && guide === 'on') {
            if (!data.users[0].address || !data.users[0].phone) {
                setGuidance(true)
                if (!guidedRef.current) {
                    showNotification(
                        <div>
                            <p>Welcome to the Turba Media onboarding guide!</p>
                            <p>You can turn the tour on or off at any time by clicking the round button with the "eye" icon in the top-right panel.</p>
                            <p>Please enter your personal details below. You can follow the blue dot from field to field to complete the form.</p>
                            <p>Note: Your phone number, business name and business address are required.</p>
                        </div>,
                        'info',
                        false,
                        'Continue',
                        () => focus('.step-1'),
                        false
                    )
                    guidedRef.current = true
                }
            }
            if ((!data.users[0].paymentMethodId && data.users[0].address && data.users[0].phone) || paymentGuidance) {
                if (!guidedPaymentRef.current) {
                    const element = document.querySelector('.step-4')
                    if (element) {
                        element.click()
                    }
                    showNotification(
                        <div>
                            <p>Please enter your credit card details and click "Update."</p>
                            <p>Your card will only be charged when you create a new campaign. The cost is $1,770 AUD plus surcharge per campaign per year.</p>
                        </div>,
                        'info',
                        false,
                        'Continue',
                        () => handleFocusCardNumberFromParent(),
                        false
                    )
                    guidedPaymentRef.current = true
                }
            }
        }
    }, [data, paymentGuidance, setGuidance, guidedPaymentRef, guidedRef, guide])

    useEffect(() => {
        if (!loading && data && data.users && data.users.length > 0) {
            if (data.users[0].notes) {
                setNotes(data.users[0].notes)
            }
            const checkElements = setInterval(() => {
                const allTargetsMounted = steps.every((step) => document.querySelector(step.target))
                if (allTargetsMounted) {
                    clearInterval(checkElements)
                    setRun(true)
                }
            }, 100)
        }
    }, [loading, data, steps, setNotes])

    return (
        <Fragment>
            <Joyride
                steps={steps}
                run={guidance && guide === 'on'}
                continuous={false}
                showProgress={false}
                showSkipButton={false}
                callback={handleTourCallback}
                styles={{ options: { primaryColor: '#437eeb' } }}
            />
            <ToastContainer />
            {loading ? (
                <Loader />
            ) : (
                <Row>
                    <Col lg="4">
                        <Elements stripe={stripe} options={appearance}>
                            <PaymentDetails userData={userData} data={data.users[0]} ref={stripeCardFormRef} />
                        </Elements>

                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">
                                        <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-25" height="25">
                                            <path opacity="0.4" d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5036 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0463C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.299 9.012 20.0475 9.013C19.6247 9.016 19.1177 9.021 18.8088 9.021Z" fill="currentColor"></path>
                                            <path opacity="0.4" d="M16.0842 2.56737C15.7852 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1742 7.55437 17.2802 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z" fill="currentColor"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.97398 11.3877H12.359C12.77 11.3877 13.104 11.0547 13.104 10.6437C13.104 10.2327 12.77 9.89868 12.359 9.89868H8.97398C8.56298 9.89868 8.22998 10.2327 8.22998 10.6437C8.22998 11.0547 8.56298 11.3877 8.97398 11.3877ZM8.97408 16.3819H14.4181C14.8291 16.3819 15.1631 16.0489 15.1631 15.6379C15.1631 15.2269 14.8291 14.8929 14.4181 14.8929H8.97408C8.56308 14.8929 8.23008 15.2269 8.23008 15.6379C8.23008 16.0489 8.56308 16.3819 8.97408 16.3819Z" fill="currentColor"></path>
                                        </svg>{' '}
                                        Notes
                                    </h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group className="col-md-12 form-group campaign-step-10-1">
                                    <Form.Control required as="textarea" id="notes" rows="8" name="notes" onChange={onChange} value={notes} />
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg="8">
                        <UserProfile
                            updateUserData={updateUserData}
                            data={data}
                            user={user}
                            notes={notes}
                            callback={userDetailsCompleted}
                        />
                    </Col>
                </Row>
            )}
        </Fragment>
    )
})

AdminProfile.displayName = 'AdminProfile'
export default AdminProfile
