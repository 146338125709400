import React, { memo, Fragment, useState, useEffect, useRef } from 'react'
import { useForm } from '../../utils/form-hook'
import { usePasswordValidation } from '../../utils/password-validation-hook'
import { usePhoneValidation } from '../../utils/phone-validation-hook'
import { googleMapsPlaces } from '../../utils/google-maps-places'
import { gql, useMutation } from '@apollo/client'
import { CORE_USER_FIELDS } from '../../graphql/user-fragments'
import { Form, Button, Alert, Spinner, Image } from 'react-bootstrap'
import Card from '../common/Card'
import PhoneFormInput from '../common/PhoneFormInput'
import ShowErrors from '../partials/ShowErrors'
import _ from 'lodash'

const UPDATE_USER = gql`
    ${CORE_USER_FIELDS}
    mutation UpdateUser($id: ID!, $userInput: UserInput) {
        updateUser(id: $id, userInput: $userInput) {
            ...CoreUserFields
        }
    }
`

const UserProfile = memo(({ updateUserData, data, user, validate, notes, callback }) => {
    const [errors, setError] = useState([])
    const [success, setSuccess] = useState(false)
    const submitRef = useRef(null)

    const updateUserCallback = async () => {
        if (!values.country) {
            const input = document.getElementById('address')
            input.setCustomValidity('Address was not recognised')
        } else {
            await updateUser()
        }
    }

    const initialValues = {
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        address: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        timeZoneOffset: null,
        location: null,
        logoImage: null,
        notes: ''
    }

    if (data?.users && data?.users.length > 0) {
        _.each(initialValues, (value, key) => {
            if (data.users[0][key] != null && key !== 'logoImage') {
                initialValues[key] = data.users[0][key]
            }
        })
    }

    const { onChange, onFileChange, onSubmit, values, validated, setValue, validateEmail, resetValidation } = useForm(
        updateUserCallback,
        initialValues
    )

    const { passwordValidation } = usePasswordValidation(values, true)
    const { isPhoneValid, phoneValidation } = usePhoneValidation(values)

    useEffect(() => {
        if (values.location) {
            setValue((prevState) => {
                return {
                    ...prevState,
                    address: values.location.address,
                    addressLine1: values.location.line1,
                    addressLine2: values.location.line2,
                    city: values.location.suburb,
                    state: values.location.state,
                    postalCode: values.location.postcode,
                    country: values.location.country,
                    timeZoneOffset: values.location.offset * 60,
                }
            })
        }
    }, [values.location, setValue])

    useEffect(() => {
        googleMapsPlaces(setValue, document.getElementById('address'))
    }, [setValue])

    useEffect(() => {
        const inputAddress = document.getElementById('address')

        if (values.timeZoneOffset !== null) {
            inputAddress.setCustomValidity('')
        } else {
            inputAddress.setCustomValidity('Address was not defined')
        }
    }, [values.location, values.timeZoneOffset])

    useEffect(() => {
        phoneValidation()
    }, [values.phone, phoneValidation])

    useEffect(() => {
        updateUserData(values)
    }, [values, updateUserData])

    useEffect(() => {
        setValue((prevState) => {
            return {
                ...prevState,
                notes: notes
            }
        })
    }, [notes])

    useEffect(() => {
        if (submitRef.current && validate) {
            submitRef.current.click()
        }
    }, [validate])

    const [updateUser, mutation] = useMutation(UPDATE_USER, {
        onError({ graphQLErrors }) {
            setError(graphQLErrors)
        },
        onCompleted: () => {
            resetValidation()
            setError([])
            setSuccess(true)
            callback()
        },
        variables: {
            id: user.id,
            userInput: _.omit(values, ['location']),
        },
    })

    return (
        <Fragment>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">
                            <i className="icon">
                                <svg
                                    className="icon-25"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        opacity="0.4"
                                        d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </i>{' '}
                            Profile
                        </h4>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="new-user-info">
                        <Form noValidate validated={validated}>
                            <div className="row">
                                <Form.Group className="col-md-4 form-group">
                                    <Form.Label htmlFor="fname">First Name:</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        id="fname"
                                        placeholder="Enter First Name"
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={onChange}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group className="col-md-4 form-group">
                                    <Form.Label htmlFor="lname">Last Name:</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        id="lname"
                                        placeholder="Enter Last Name"
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={onChange}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group className="col-md-4 form-group step-1">
                                    <Form.Label htmlFor="mobno">Phone Number:</Form.Label>
                                    <PhoneFormInput
                                        value={values.phone}
                                        setValue={setValue}
                                        validated={validated}
                                        isValid={isPhoneValid}
                                    />
                                    {validated && !isPhoneValid && (
                                        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                            Phone number is invalid
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </div>
                            <hr />
                            <h5 className="mb-3">Billing</h5>
                            <div className="row">
                                <Form.Group className="col-md-12 form-group step-1-1">
                                    <Form.Label htmlFor="lname">Business Name:</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        id="bname"
                                        placeholder="Enter Business Name"
                                        name="businessName"
                                        value={values.businessName}
                                        onChange={onChange}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group className="col-md-12 form-group step-2">
                                    <Form.Label htmlFor="address">Business Address:</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        id="address"
                                        placeholder="Enter Address"
                                        name="address"
                                        value={values.address}
                                        onChange={onChange}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {values.address.length > 0 ? 'Address was not recognised' : ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <hr />
                            <h5 className="mb-3">Login Details</h5>
                            <div className="row">
                                <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="email">Email:</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        id="email"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={values.email}
                                        onChange={onChange}
                                        onKeyUp={validateEmail}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {values.email.length > 0 ? 'Please enter a valid Email' : ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="password">Password:</Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="password"
                                        placeholder="Leave empty to not update"
                                        name="password"
                                        onChange={onChange}
                                        onKeyUp={passwordValidation}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {values.password.length > 0 ? 'Weak Password' : ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="confirmPassword">Repeat Password:</Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="confirmPassword"
                                        placeholder="Leave empty to not update"
                                        name="confirmPassword"
                                        onChange={onChange}
                                        onKeyUp={passwordValidation}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {values.password !== values.confirmPassword ? 'Passwords do not match' : ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <hr />
                            <h5 className="mb-3">Your Logo</h5>
                            <div className="row">
                                <Form.Group className="form-group">
                                    <Form.Label className="custom-file-input">Update Logo</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept=".jpg,.gif,.png"
                                        id="logo-image"
                                        name="logoImage"
                                        onChange={onFileChange}
                                    />
                                </Form.Group>
                            </div>
                            <div className="pb-3">
                                <ShowErrors errors={errors} />
                                <Alert
                                    variant="success alert-left alert-dismissible fade show mb-3"
                                    role="alert"
                                    show={success}
                                    onClose={() => setSuccess(false)}
                                    dismissible
                                >
                                    <span> User Profile has been successfully updated </span>
                                </Alert>
                            </div>
                            <Button
                                type="button"
                                variant="btn btn-primary"
                                onClick={onSubmit}
                                disabled={mutation.loading}
                                ref={submitRef}
                                className="step-3"
                            >
                                {mutation.loading ? (
                                    <Fragment>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        Updating...
                                    </Fragment>
                                ) : (
                                    <Fragment>Update</Fragment>
                                )}
                            </Button>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
        </Fragment>
    )
})

UserProfile.displayName = 'UserProfile'
export default UserProfile
